/*
  @TODO: Use more than 1 type of options object based on use cases
  @TODO: Think more about fetchLinks and usage
*/
import isEmpty from 'lodash.isempty'
import { isValidRoute, getLanguageFromRoute } from '~/utils/language'
import {
  loadDocument,
  isPageBlacklisted,
} from '~/mixins/prismicCustomType/utils'
import commonFetchLinksOptions from '~/mixins/utils'
import { loadFeatureFlags } from '~/utils/loadFeatureFlags'
import { loadMenu } from '~/utils/loadMenu'

const fetchLinks = [...commonFetchLinksOptions]
const DEFAULT_THEME = 'dark-navy'

// fetches prismic document based on a `type`
// and attaches the data to the component
// the document can be accessed via `this.document`
export default ({ type, options = {}, repeatableType = null, theme }) => ({
  // used only for testing
  data() {
    return {
      document: null,
    }
  },

  async asyncData({ $prismic, route, error, payload, store }) {
    const lang = getLanguageFromRoute(route)

    await loadFeatureFlags($prismic, store, {
      ...options,
      lang,
    })

    await loadMenu($prismic, store)

    if (!isValidRoute(route)) {
      error({ statusCode: 404, message: 'Page not found' })
    }

    let document = payload

    const isBlacklisted = isPageBlacklisted(store, type)

    if (isBlacklisted) {
      error({ statusCode: 404, message: 'Page not found' })
    }

    // main static page - single type
    if (isEmpty(document)) {
      document = await loadDocument(
        type,
        $prismic,
        {
          fetchLinks,
          ...options,
          lang,
        },
        error
      )
    }

    // set the theme
    const themeToSet = document?.data?.theme || theme || DEFAULT_THEME
    store.commit('theme/setTheme', themeToSet)

    return {
      document,
      lang,
      type,
      theme: themeToSet,
    }
  },
})
