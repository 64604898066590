import fetchDynamicPages from '~/buildModules/fetchDynamicPages'
export const DEBUG_PAGE_CUSTOM_TYPE = 'debug'

export const isPageBlacklisted = (store, type = null) => {
  if (type === null) {
    return false
  }

  if (store.state.featureFlags.black_listed_pages) {
    // disable debug page, manually activate it when needed
    // DON'T FORGET to add deactivate it after finished testing
    const blacklist = `${store.state.featureFlags.black_listed_pages[0].text}, "${DEBUG_PAGE_CUSTOM_TYPE}"`
    return blacklist.includes(type)
  }

  return false
}

// sub pages --- repeatable types
export const loadDocuments = async (apiUrl, repeatableType) => {
  return await fetchDynamicPages(apiUrl, repeatableType)
}

export const loadDocument = async (
  customType,
  $prismic,
  options,
  errorCallback
) => {
  const document = await $prismic.api.getSingle(customType, options)

  if (!document) {
    errorCallback({ statusCode: 404, message: 'Page not found' })
  }

  const promise = new Promise((resolve) => {
    resolve(document)
  })

  return promise
}
export const loadDocumentById = async (
  customType,
  uid,
  $prismic,
  options,
  errorCallback
) => {
  const document = await $prismic.api.getByUID(customType, uid, options)

  if (!document) {
    errorCallback({ statusCode: 404, message: 'Page not found' })
  }

  const promise = new Promise((resolve) => {
    resolve(document)
  })

  return promise
}
