import get from 'lodash.get'

export default {
  head() {
    let imageUrl = get(this, 'document.data.web_page_image.url', '')

    if (imageUrl.indexOf('https') === 0) {
      imageUrl = `${get(this, '$config.siteMapUrl')}${imageUrl}`
    }

    return {
      htmlAttrs: {
        lang: this.lang,
      },
      title: get(this, 'document.data.web_page_title[0].text', ''),
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: get(this, 'document.data.web_page_description[0].text', ''),
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: get(this, 'document.data.web_page_keywords[0].text', ''),
        },
        {
          hid: 'image',
          name: 'image',
          content: imageUrl,
        },

        // twitter
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary',
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: get(this, 'document.data.web_page_title[0].text', ''),
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: get(this, 'document.data.web_page_description[0].text', ''),
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: imageUrl,
        },

        // Open Graph
        {
          hid: 'og:title',
          property: 'og:title',
          content: get(this, 'document.data.web_page_title[0].text', ''),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: get(this, 'document.data.web_page_description[0].text', ''),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: imageUrl,
        },
      ],
    }
  },
}
