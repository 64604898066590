export const commonFetchLinksOptions = [
  'person.firstName',
  'person.lastName',
  'person.jobTitle',
  'person.company',
  'person.biography',
  'person.linkedinUrl',
  'person.githubUrl',
  'person.twitterUrl',
  'person.image',
]

export default commonFetchLinksOptions
